import { UserLogin } from '@vivino/js-web-common';
import React from 'react';
import ReactDOM from 'react-dom';
import { UserProvider } from 'vivino-js/context/UserContext';
import Page from 'vivino-js/utilities/Page';

function render() {
  const element = document.getElementById('user-login');
  element &&
    ReactDOM.render(
      <Page latest>
        <UserProvider user={window.__PRELOADED_SIGNED_IN_USER__}>
          <UserLogin
            initialLoginState={window.__PRELOADED_LOGIN_STATE__}
            loginRedirect={window.__PRELOADED_REDIRECT_AFTER_LOGIN__}
            countryCode={window.__PRELOADED_COUNTRY_CODE__}
            trackingPage={window.__PRELOADED_TRACKING_SCREEN_NAME__}
          />
        </UserProvider>
      </Page>,
      element
    );
}

render();

export { render };
